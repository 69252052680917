<template>
  <div class="form-wrapper">
    <div class="form-sent" v-if="sent">
      <h4>Thank you! We look forward to working with you!</h4>
      <!--<p>While you wait, check out some of our previous work</p>
      <router-link to="/portfolio"><button>Portfolio</button></router-link>-->
    </div>

    <form id="contactForm" @submit.prevent="sendEmail" v-if="!sent">
      <h4>Looking for an expert? Drop us a request below:</h4>
      <div class="form-errors" v-if="errors.length">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
      </div>

      <div>
        <label for="your-name">Name:</label>
        <input
          id="your-name"
          v-model="name"
          type="text"
          name="your-name"
          autofocus
        />
      </div>

      <div>
        <label for="your-email">Email:</label>
        <input id="your-email" v-model="email" type="text" name="your-email" />
      </div>

      <div>
        <label for="your-phone">Phone:</label>
        <input id="your-phone" v-model="phone" type="text" name="your-phone" />
      </div>

      <div>
        <label for="your-message">Message:</label>
        <textarea
          id="your-message"
          v-model="message"
          type="text"
          name="your-message"
          rows="6"
        ></textarea>
      </div>
      <div>
        <button type="submit" value="Submit">Submit</button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";

export default defineComponent({
  name: "ContactForm",
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      message: null,
      errors: [],
      sent: null,
    };
  },

  methods: {
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    sendEmail(e) {
      e.preventDefault();
      this.errors = [];

      if (!this.name) {
        this.errors.push("Name required");
      }

      if (!this.email) {
        this.errors.push("Email required");
      } else if (!this.validateEmail(this.email)) {
        this.errors.push("Please enter a valid email");
      }

      if (!this.message) {
        this.errors.push("Message required");
      }

      if (!this.errors.length) {
        try {
          var emailData = new FormData();
          emailData.set("your-name", this.name);
          emailData.set("your-email", this.email);
          emailData.set("your-phone", this.phone);
          emailData.set("your-message", this.message);

          axios({
            method: "post",
            url: "https://turpinlandscape.com/portal/wp-json/contact-form-7/v1/contact-forms/19/feedback",
            data: emailData,
            config: { headers: { "Content-Type": "multipart/form-data" } },
          })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (response) {
              console.log(response);
            });
        } finally {
          console.log("finally");
          this.name = "";
          this.email = "";
          this.message = "";
          this.sent = true;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
.form-sent {
  h4 {
    font-size: 1.4rem;
    color: var(--header-text-green);
    margin: 10px;
  }

  p {
    font-size: 1.2rem;
    margin: 5px 0 20px 0;
  }
}

#contactForm {
  width: 100%;
  max-width: 800px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;

  .form-errors {
    background: rgba(95, 158, 160, 0.4);
    width: 100%;
    margin: 20px auto;
    border-radius: 5px;
    padding: 10px 0;

    b {
      margin: 10px 0 0 0;
      text-align: center;
    }

    ul {
      width: 80%;
      margin: 15px auto;
      li {
        padding: 5px 0;
      }
    }
  }

  h4 {
    font-size: 1.4rem;
    color: var(--header-text-green);
    margin: 20px 0 30px 0;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 80%;
    max-width: 500px;

    label {
      width: 100%;
      font-size: 1.2rem;
      margin: 0;
      padding: 0;
      font-family: "Roboto";
    }

    input,
    textarea {
      font-family: "Roboto";
      font-size: 1rem;
      padding: 5px 0;
      text-indent: 15px;
      border-radius: 5px;
      border: 2px solid var(--dark-green);
      outline: none;

      &:focus {
        background: rgba(133, 140, 108, 0.2);
      }
    }

    input {
      width: 100%;
      margin: 2px 0 15px 0;
      height: 30px;
    }
  }
  button {
    margin: 20px auto;
    width: 50%;
    font-size: 1rem;
    outline: none;

    &:focus {
      text-indent: 30px;

      &:before {
        transform: scale(1, 1);
        text-indent: 0;
      }
    }
  }
}
</style>
