<template>
  <PageHeader>
    <h1>Contact</h1>
    <img src="@/assets/img/hero.jpg" />
  </PageHeader>

  <div class="contact">
    <ContactForm />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/global/PageHeader.vue";
import ContactForm from "@/components/ContactForm.vue";

export default defineComponent({
  components: {
    PageHeader,
    ContactForm,
  },
  data() {
    return {
      sent: false,
      form: {
        name: "",
        email: "",
        phone: "",
        maintenance: "",
        design: "",
        install: "",
        comments: "",
      },
    };
  },
});
</script>

<style scoped lang="scss">
.contact {
  width: 80%;
  max-width: 800px;
  margin: 40px auto;
}
</style>
